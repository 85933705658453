.header{
    position: sticky;
    top: 0;
    z-index: 2;
}
.top{
    padding: 10px 5%;
    background-color: var(--background);
}
.top p{
    font-size: 0.875rem;
}
.top i{
    font-size: 0.875rem;
}
.navigator{
    padding: 10px 5%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    background-color: whitesmoke;
}
.navigator div a{
    display: inline-block;
    font-size: 14px;
    margin-right: 0.5rem;
    border-radius: 0.125rem;
    border: 1px solid var(--btnBackground);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-weight: 500;
    color: var(--btnBackground);
}
.navigator div a:hover{
    background-color: aliceblue;
}
.navigator div a:last-child{
    background-color: var(--btnBackground);
    color: white;
}
.navigator div a:last-child:hover{
    background-color: var(--btnHover);
}
.navigator div img{
    width: 25px;
    height: 25px;
    border-radius: 100%;
}
.navigator div p{
    font-size: 14px;
    font-weight: 200;
}
.navigator img{
    width: 10%;
}
.navigator nav a{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: black;
    font-weight: 100;
    font-size: 14px;
}
.navigator nav a:hover{
    color: var(--background);
}
@media only screen and (max-width: 998px){
    .navigator nav{
        overflow: auto;
    }
    .navigator div{
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
    .navigator img{
        width: 30%;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .navigator nav a{
        font-size: 14px;
        font-weight: 700;
        color: gray;
        max-width: 200px;
        flex-shrink: 0;
    }
    .top div:nth-child(1){
        width: 70%;
    }
    .navigator form{
        width: 100%;
    }
    .top span{
        display: none;
    }
    .top div:first-child p{
        font-weight: 700;
    }
}