.App {
  text-align: normal;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

table td, th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background-color: #04AA6D;
  background-color: var(--btnBackground);
  color: white;
}
.footer{
  padding: .5% 5%;
  background-color: var(--background);
  color: white;
}
.footer p{
  font-size: 0.875rem;
}
.footer small{
  display: block;
  font-size: 0.83rem;
}
h4{
  font-size: 18px;
  border-left: 5px solid var(--background);
  padding-left: 10px;
  margin-bottom: 20px;
}