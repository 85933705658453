.productDetails{
    padding: 0 10%;
    display: flex;
}
.productDetails .imgSlidder{
    width: 50%;
}
.productDetails .pDetails{
    width: 25%;
}
@media only screen and (max-width: 998px){
    .productDetails .imgSlidder{
        width: 100%;
    }
    .productDetails .pDetails{
        width: 100%;
    }
}