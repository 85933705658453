.productPage{
    padding: 0;
}
.productForm{
    width: 100%;
    margin: 2.5% auto;
    padding: 20px;
    /*
    box-shadow: 11px 9px 6px -2px rgba(0,0,0,0.67);
    -webkit-box-shadow: 11px 9px 6px -2px rgba(0,0,0,0.67);
    -moz-box-shadow: 11px 9px 6px -2px rgba(0,0,0,0.67);
    background: #ddd;
    */
}