.activation{
    background-color: antiquewhite;
    width: 100%;
    height: 100vh;
}
.activation .inner{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.activation h1{
    font-size: 1.5rem;
    font-weight: 900;
    text-align: center;
}