/* ProductCard.css */

.product-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    width: 280px;
    margin-bottom: 20px;
}

.product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-card-image {
    height: 200px;
    overflow: hidden;
}

.product-card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product-card-body {
    padding: 12px;
}

.product-card-header {
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
}

.product-name {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 900;
    color: #333;
    color: var(--background);
}
.product-name:hover{
    text-decoration: underline;
}

.part-number {
    margin: 0;
    color: #666;
}

.stock-amount {
    margin: 0;
    color: #666;
}

.price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
    color: #333;
    color: var(--background)
}

.product-card-footer {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-to-cart-btn {
    background-color: var(--background);
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
    background-color: #0056b3;
}

@media only screen and (max-width: 998px){
    .product-card{
        width: 100%;
    }
}