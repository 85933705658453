/* ProductImageSlider.css */
.product-card-skeleton{
    width: 22%;
    flex-grow: 1;
}
.product-image-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.image-container {
    position: relative;
    margin-bottom: 20px;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
    z-index: 1;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.prev-button:hover,
.next-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.thumbnail-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.thumbnail {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.thumbnail.active {
    border-color: #007bff;
}
@media only screen and (max-width: 998px){
    .product-card-skeleton{
        width: 100%;
        flex-grow: 1;
    }
}
