.item{
    list-style: none
}
.item li:hover{
    background-color: rgb(237, 237, 237);
    cursor: pointer;
}

.imagesDiv div{
    width: 20%;
}
.imagesDiv div img{
    width: 100%;
    height: 150px;
    object-fit: contain;
}


/* cover image update code */
/* Home.css */
.coverUpdate {
    position: relative;
    display: inline-block;
}
.coverUpdate:hover{
    cursor: pointer;
}

.coverUpdate img {
    width: 150px;
}

.coverUpdate .btn {
    /*
    position: absolute;
    bottom: 10px; 
    left: 10px;  
    */
    opacity: 1;
    transition: opacity 0.3s ease;
}

.coverUpdate:hover .btn {
    opacity: 1;
}
