.no-data {
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.no-data-container {
    text-align: center;
    margin: 20px;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #666;
}

.no-data-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.no-data-container p {
    font-size: 18px;
    margin-bottom: 0;
}

@media only screen and (max-width: 998px){
    .no-data {
        height: 50vh;
    }
}
