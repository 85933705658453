#loginForm form{
    width: 30%;
    margin: auto;
    padding-top: 40px;
}
#loginForm form h2{
    color: var(--background);
}
#loginForm input{
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#loginForm ul{
    padding: 0;
    margin: 0;
}
#loginForm ul li{
    list-style: none;
    color: grey;
    font-size: 0.9rem;
}
#loginForm input[type="submit"]{
    background: var(--background);
    border-radius: 5px;
    padding: 5px;
    color: white;
    border: none;
    transition: 1s;
}
#loginForm input[type="submit"]:hover{
    background: var(--btnHover);
}
@media only screen and (max-width: 998px){
    #loginForm form{
        width: 90%;
        margin: auto;
        padding-top: 40px;
    }
}