.cartItem{
    padding: 2.5% 10%;
    min-height: 77.5vh;
}
.cartItem .h4{
    background-color: #dddddd;
    font-size: 1.1rem;
    padding: 10px;
    color: var(--background);
}
.cartItem img{
    width: 80px;
    height: 80px;
    padding: 10px 0;
    object-fit: contain;
}
.cartItem ul li{
    list-style: none;
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(228, 228, 228);
}
.cartItem ul li h3{
    font-size: 1.1rem;
}
.cartItem .divided{
    display: flex;
}
.cartItem .divided ul{
    padding: 0;
    margin: 0;
    width: 68%;
}
.cartItem .divided .last{
    width: 30%;
    background-color: rgb(36, 36, 36);
    color: white;
    padding: 20px;
}
.cartItem .divided .last em{
    padding-bottom: 10px;
    display: inline-block;
}
.cartItem .divided .last h3{
    font-size: 1.3rem;
    text-transform: uppercase;
    padding-bottom: 20px;
}
.cartItem .divided .last div{
    padding-bottom: 20px;
}
.cartItem .divided .last button{
    width: 100%;
    text-align: center;
    padding: 10px;
    background-color: orangered;
    color: white;
}
@media only screen and (max-width: 998px){
    .cartItem{
        padding: 2.5% 5%;
        min-height: auto;
    }
    .cartItem .divided{
        display: flex;
    }
    .cartItem .divided ul{
        width: 100%;
    }
    .cartItem .divided .last{
        width: 100%;
    }
    .cartItem ul li{
        display: block;
    }
    .cartItem ul li button{
        width: 48%;
    }
    .cartItem img{
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}