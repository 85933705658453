/* InquiryList.css */
.card {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    transition: transform 0.3s;
}

.heading {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.text {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 12px;
}

.card.answered {
    background-color: #f2f2f2; /* Gray background for answered inquiries */
}

.card.answered:hover {
    transform: none; /* Remove hover effect on answered inquiries */
}

.card.answered:active {
    transform: scale(1); /* Prevent tap effect on answered inquiries */
}
