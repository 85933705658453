.bg{
    background: url('../../public/images/bg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    position: relative;
    min-width: 210mm;
    max-width: 100%;
}
.QuotationHeader{
    margin: 0 10%;
    padding: 2.5% 2.5%;
    background-color: rgba(255, 255, 255, 0.9);
}
.bg::before{
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.2);
    z-index: -1;
}

.QuotationHeader .one{
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d8d8;
}
.QuotationHeader .one img{
    width: 100px;
    height: 70px;
    object-fit: contain;
}
.QuotationHeader .one p{
    font-size: 1rem;
    color: #333;
}
.QuotationHeader .one a{
    color: var(--background);
}
.QuotationHeader .two{
    padding-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.QuotationHeader .two p{
    font-size: 1rem;
    color: #333;
}
.QuotationHeader .three{
    display: flex;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d8d8;
}
.QuotationHeader h2{
    font-size: 1.4rem;
    padding: 20px 0;
}
.QuotationHeader .three div{
    width: 20%;
}
.QuotationHeader .three div h3{
    font-size: 1rem;
}
.QuotationHeader .three div p{
    color: #333;
    margin: 0;
}
.QuotationHeader .four{
    display: flex;
    justify-content: flex-end;
}
.QuotationHeader .four .parent{
    width: 40%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #d9d8d8;
    border-bottom: 1px solid #d9d8d8;
}
.QuotationHeader .four .parent div{
    display: flex;
    justify-content: space-between;
}
.QuotationHeader .four .parent div p{
    font-size: 1rem;
}
.QuotationHeader .four .parent div p:last-child{
    color: #333;
}
.QuotationHeader .four .parent div p:first-child{
    font-weight: 500;
}
.QuotationHeader .five div{
    padding: 20px 0;
}
.QuotationHeader .five p{
    font-size: 1rem;
    color: #333;
}
.QuotationHeader .five .p{
    color: maroon;
    font-weight: 500;
    padding-top: 20px;
}
.QuotationHeader table th:nth-child(2){
    width: 40%;
}

@media only screen and (max-width:998px){
    .QuotationHeader {
        min-width: 250mm; /* A4 width in mm */
        /*min-height: 297mm;  A4 height in mm */
    }
}
