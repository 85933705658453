.forgotPassword{
    width: 30%;
    margin: auto;
    border: 1px solid rgb(220, 219, 219);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 6px 10px 5px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 6px 10px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 6px 10px 5px 0px rgba(0,0,0,0.5);
}
@media only screen and (max-width: 998px){
    .forgotPassword{
        width: 80%;
    }
}