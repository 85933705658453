.home-container {
    display: flex;
    height: 78vh; /* for sticky positioning of scrollable content */
}
  
.side-panel {
    padding: 10px;
    width: 18%; /* Adjust the width as needed */
    height: 78vh; /* Set the height to full viewport height */
    /*background-color: #f0f0f0;  Set the background color */
    position: sticky;
    top: 0;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    z-index: 1;
}
  
.scrollable-content {
    width: 82%;
    flex-grow: 1; /* Allow the right part to grow and take available space */
    padding: 20px; /* Add padding for better appearance */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}
.side-panel a{
    display: block;
    padding: 20px;
    background-color: var(--backgroundLight);
    margin: 5px 0;
    border-left: 10px solid var(--background);
    border-radius: 5px;
    color: black;
    font-size: 14px;
    transition: .5s;
}
.side-panel a:hover{
    scale: 1.05;
}
.side-panel a:active{
    scale: 0.9;
}
.welcome h1{
    font-size: 2rem;
    color: red;
    font-weight: 700;
}
.welcome h1 span{
    color: var(--background);
    font-weight: 900;
}
.welcome h2{
    font-size: 1.2rem;
}
@media only screen and (max-width: 998px){
    .home-container {
        display: block;
        height: auto;
    }
    .side-panel {
        padding: 10px;
        width: 100%;
        height: auto; 
        position: relative;
        display: flex;
        overflow-x: auto;
        /*flex-wrap: wrap*/
    }

    .side-panel a{
        width: auto;
        flex-shrink: 0;
        gap: 10px;
        display: block;
        max-width: 150px;
        padding: 10px;
        background-color: var(--backgroundLight);
        margin: 5px 5px;
    }

    .welcome h1{
        font-size: 1.4rem;
        color: red;
        font-weight: 700;
        margin-top: -20px;
    }
    .welcome h1 span{
        color: var(--background);
        font-weight: 700;
        font-size: 1.2rem;
    }
    .welcome h2{
        font-size: 0.9rem;
    }

    .welcome img{
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
        margin-top: 20px;
    }
      
    .scrollable-content {
        width: 100%;
        flex-grow: 1; /* Allow the right part to grow and take available space */
        padding: 20px; /* Add padding for better appearance */
        overflow-y: auto; /* Enable vertical scrolling if content overflows */
    }
}