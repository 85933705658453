/* ProductImageSlider.css */

.product-image-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.main-image-container {
    margin-bottom: 20px;
    position: relative;
}
.main-image {
    max-width: 100%;
    height: auto;
    height: 400px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    object-fit: contain;
}

.thumbnail-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.thumbnail {
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.thumbnail.active {
    border-color: var(--background);
}

.prev-button,
.next-button {
    background-color: var(--background);
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    transition: background-color 0.3s ease;
}

.prev-button:hover,
.next-button:hover {
    background-color: #0056b3;
}

@media only screen and (max-width: 998px){
    .main-image {
        max-width: 100%;
        height: auto;
    }
    .prev-button,
    .next-button {
        padding: 5px 7.5px;
    }
}