.jobs h2{
    font-size: 1.2rem;
}
.jobs button{
    display: block;
    font-size: 14px;
    margin-right: 0.5rem;
    border-radius: 0.125rem;
    border: 1px solid var(--btnBackground);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-weight: 500;
    color: white;
    background-color: var(--btnBackground);
    margin-bottom: 20px;
}
.jobs span{
    font-size: 0.875rem;
    font-weight: 200;
}
.jobs p{
    font-size: 14px;
    color: gray;
    padding: 5px 0;
}
/* landing page */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    font-size: 1rem;
}

/* Header Styles */
header {
    background-color: var(--background);
    color: #fff;
    padding: 20px 0;
    text-align: center;
}

header h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

header p {
    font-size: 1.1em;
}

/* Main Styles */
.about {
    padding: 30px 0;
    background-color: #f9f9f9;
    text-align: center;
}

.about p {
    font-size: 1em;
    margin-bottom: 10px;
    text-align: left;
}

/* Contact Section Styles */
.contact {
    padding: 30px 0;
    background-color: #E9ECEF;
    text-align: center;
}

.contact h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.contact p {
    margin-bottom: 10px;
    text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
    header {
        padding: 50px 0;
    }
    
    header h1 {
        font-size: 2.5em;
    }
    
    .about,
    .contact {
        padding: 40px 0;
    }
}