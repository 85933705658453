*{
  box-sizing: border-box;
}
*, p{
  padding: 0;
  margin: 0;
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;
}
:root{
  --background: #007bc7;
  --btnBackground: rgba(21, 126, 194, 1);
  --btnHover: rgb(11, 80, 126);
  --backgroundLight: rgb(161, 217, 254);
  --backgroundSelected: rgb(255, 0, 0);
}
a{
  text-decoration: none;
}
.active{
  border-bottom: 2px solid var(--btnBackground);
}
a.activeSelection{
  border-right: 2px solid var(--backgroundSelected);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input, textarea, select{
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  /*margin-bottom: 10px;*/
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input, select, textarea{
  outline: none;
}

textarea{
  height: auto;
}
label{
  width: 100%;
  font-size: 14px;
}
